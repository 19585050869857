@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root  {
  @apply h-full dark:bg-black dark:text-white;
}

a {
  @apply underline;
}